<template>
  <nav class="h-100 bg-base-white">
    <div
      class="navbar-container container-fluid container-lg h-100 d-flex justify-content-between align-items-center"
    >
      <logo :show-zasilkovna-logo="showZasilkovnaLogo" />
      <div v-if="!isMobile" class="d-flex gap-8 me-4">
        <track-shipment-input v-if="showShipmentTracking" />
        <atom-button
          navbar
          :data-id="DataIdElement.CUSTOMER_SERVICE_LINK"
          class="paragraph-small"
          :link="t('customerServiceLinkUrl')"
          new-window
        >
          <icon-emoji-smile class="me-8" />
          {{ t('customerServiceLink') }}
        </atom-button>

        <atom-dropdown-menu :options="langMenuOptions">
          <template #trigger="{ handleClick, menuVisible }">
            <div @click="handleClick">
              <atom-button
                navbar
                :class="{ active: menuVisible }"
                :aria-label="t('ariaLabel.openLangMenu')"
              >
                <icon-globe class="me-4" />
                <icon-arrow
                  viewBox="0 0 24 24"
                  width="16"
                  height="16"
                  :class="{ rotate: menuVisible }"
                />
              </atom-button>
            </div>
          </template>
        </atom-dropdown-menu>
      </div>
      <div v-else class="d-flex flex-column">
        <atom-button
          v-if="!mobileMenuVisible"
          navbar
          :aria-label="t('ariaLabel.expandMenu')"
          class="p-4 text-base-black"
          :data-id="DataIdElement.HAMBURGER_BTN"
          @click="handleMobileMenuOpen"
        >
          <icon-hamburger />
        </atom-button>
        <atom-button
          v-else
          navbar
          :aria-label="t('ariaLabel.hideMenu')"
          :data-id="DataIdElement.CLOSE_BTN"
          class="p-4 text-base-black"
          @click="handleMobileMenuClose"
        >
          <icon-close />
        </atom-button>
        <div
          data-id="navbar-content"
          class="navbar-content bg-base-white d-flex flex-column justify-content-between"
          :class="{ visible: mobileMenuVisible }"
        >
          <div class="d-flex flex-column">
            <navbar-mobile-item
              :href="t('customerServiceLinkUrl')"
              :title="t('customerServiceLink')"
              :data-id="DataIdElement.CUSTOMER_SERVICE_LINK"
              :icon="IconEmojiSmile"
            />
            <navbar-mobile-item
              :title="langNames[locale as keyof typeof langFlags]"
              :icon="IconGlobe"
              class="w-100"
              @click="languageMenuItem = true"
            />
            <navbar-mobile-item
              v-if="showShipmentTracking"
              :title="t('trackShipment')"
              :data-id="DataIdElement.TRACKING_MENU_BTN"
              :icon="IconSearchSimple"
              class="w-100"
              @click="trackingMenuItem = true"
            />
          </div>
          <cookie-bar />
        </div>
      </div>
    </div>
  </nav>

  <track-shipment-input
    v-if="isMobile && showShipmentTracking"
    :default-open="trackingMenuItem"
    @close="trackingMenuItem = false"
  />

  <molecule-modal
    use-only-mobile-version
    :default-open="languageMenuItem"
    @close="languageMenuItem = false"
  >
    <template #content>
      <modal-content :title="t('language')">
        <template #content>
          <atom-native-select-input
            :options="langMenuOptions"
            :default-value="locale"
            class="mt-16"
            @select:option="mobileLanguage = $event"
          />
        </template>
      </modal-content>
    </template>

    <template #footer="{ close }">
      <div
        class="lang-modal-actions d-flex flex-column-reverse flex-xs-row align-items-sm-center justify-content-end gap-16"
      >
        <atom-button
          large
          outline-grey
          class="justify-content-center paragraph-heavy"
          @click="close()"
        >
          {{ t('back') }}
        </atom-button>
        <atom-button
          large
          class="change-language-btn justify-content-center paragraph-heavy"
          @click="selectLanguage(close)"
        >
          {{ t('saveAndContinue') }}
        </atom-button>
      </div>
    </template>
  </molecule-modal>
</template>

<script setup lang="ts">
import IconGlobe from '@web/packeta-ui-styleguide/icons/ecology/icon-globe.svg'
import IconClose from '@web/packeta-ui-styleguide/icons/map/icon-close.svg'
import IconSearchSimple from '@web/packeta-ui-styleguide/icons/map/icon-search-simple.svg'
import IconArrow from '@web/packeta-ui-styleguide/icons/other/icon-arrow.svg'
import IconHamburger from '@web/packeta-ui-styleguide/icons/other/icon-hamburger.svg'
import IconEmojiSmile from '@web/packeta-ui-styleguide/icons/status/icon-emoji-smile.svg'
import { useRoute } from 'nuxt/app'
import { defineAsyncComponent, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { DataIdElement } from '~common/enums/tests.ts'
import { langFlags, langNames, routerPushSsr } from '~common/helpers'
import type { NavbarInnerProps } from '~common/types'

const AtomButton = defineAsyncComponent(() => import('~components/atoms/AtomButton.vue'))
const AtomDropdownMenu = defineAsyncComponent(
  () => import('~components/atoms/AtomDropdownMenu.vue'),
)
const AtomNativeSelectInput = defineAsyncComponent(
  () => import('~components/atoms/AtomNativeSelectInput.vue'),
)
const MoleculeModal = defineAsyncComponent(() => import('~components/molecules/MoleculeModal.vue'))
const Logo = defineAsyncComponent(() => import('~components/navbar/Logo.vue'))
const NavbarMobileItem = defineAsyncComponent(
  () => import('~components/navbar/NavbarMobileItem.vue'),
)
const TrackShipmentInput = defineAsyncComponent(
  () => import('~components/navbar/TrackShipmentInput.vue'),
)
const CookieBar = defineAsyncComponent(
  () => import('~components/pages/id/right-side/CookieBar.vue'),
)
const ModalContent = defineAsyncComponent(() => import('~components/shared/ModalContent.vue'))

const { t, locale } = useI18n()
const route = useRoute()
const localePath = useLocalePath()

defineProps<NavbarInnerProps>()

const mobileMenuVisible = ref(false)
const languageMenuItem = ref(false)
const trackingMenuItem = ref(false)

const handleMobileMenuOpen = () => {
  mobileMenuVisible.value = true
}
const handleMobileMenuClose = () => {
  mobileMenuVisible.value = false
}

const mobileLanguage = ref(locale.value)
const selectLanguage = (close: () => void) => {
  close()
  handleMobileMenuClose()

  const to = localePath(route.path, mobileLanguage.value)
  routerPushSsr(to)
}

watch(
  () => route.path,
  () => {
    handleMobileMenuClose()
  },
  { deep: true },
)
</script>

<style lang="scss" scoped>
@media (max-width: $media-md) {
  nav {
    border-bottom: 1px solid $grey-100-borders;
  }

  .change-language-btn {
    width: 100%;
  }
}

.rotate {
  rotate: 180deg;
}

.navbar-content {
  z-index: -1;
  position: fixed;
  height: 100dvh;
  width: 100%;
  padding-top: 88px;
  top: 0;
  right: -100vw;
  transition: right 0.3s;
}

.navbar-content.visible {
  right: 0;
}

@media (min-width: $media-md) {
  .navbar-content {
    padding-top: 71px;
  }
}

.lang-modal-actions:deep(.btn) {
  height: 50px;
}
</style>
